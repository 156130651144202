<template>
<div class="container">
     <Card style="margin-top:24px;">
         <p slot="title">
            <Icon type="md-key" />
            第三方平台api接口秘钥
        </p>
        <Tabs value="abosend" :animated="false" @on-click="onClickTab">
            <Button @click="openAddAccount" size="small" slot="extra">增加</Button>
            <!-- 1.abosend -->
            <TabPane label="abosend" name="abosend">
                <Table :columns="columns" :data="TPPlatformApiAccounts.abosend.slice((abosend.page - 1) * abosend.size, abosend.page * abosend.size)" :loading="tableLoading">
                    <template slot-scope="{ row }" slot="error">
                        <Badge :status="row.error ? 'error':'success'" />
                        <span>{{row.error ? '账号异常':'正常'}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="balance">
                        <span>{{row.balance}} {{row.currency}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button type="error" size="small" @click="delAccount('abosend', row.uname)">删除</Button>
                    </template>
                </Table>
                <!-- 分页 -->
                <Page
                    :total="TPPlatformApiAccounts.abosend.length"
                    :current="abosend.page"
                    :page-size="abosend.size"
                    show-total
                    show-elevator
                    @on-change="page => abosend.page = page"
                    transfer
                    style="margin:24px 0;text-align:center;"/>
            </TabPane>
            <!-- 2.vonage -->
            <TabPane label="vonage" name="vonage">
                <Table :columns="columns" :data="TPPlatformApiAccounts.vonage.slice((vonage.page - 1) * vonage.size, vonage.page * vonage.size)" :loading="tableLoading">
                    <template slot-scope="{ row }" slot="error">
                        <Badge :status="row.error ? 'error':'success'" />
                        <span>{{row.error ? '账号异常':'正常'}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="balance">
                        <span>{{row.balance}} {{row.currency}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button type="error" size="small" @click="delAccount('vonage', row.uname)">删除</Button>
                    </template>
                </Table>
                <!-- 分页 -->
                <Page
                    :total="TPPlatformApiAccounts.vonage.length"
                    :current="vonage.page"
                    :page-size="vonage.size"
                    show-total
                    show-elevator
                    @on-change="page => vonage.page = page"
                    transfer
                    style="margin:24px 0;text-align:center;"/>
            </TabPane>
            <!-- 3.smsto -->
            <TabPane label="smsto" name="smsto">
                <Table :columns="columns" :data="TPPlatformApiAccounts.smsto.slice((smsto.page - 1) * smsto.size, smsto.page * smsto.size)" :loading="tableLoading">
                    <template slot-scope="{ row }" slot="error">
                        <Badge :status="row.error ? 'error':'success'" />
                        <span>{{row.error ? '账号异常':'正常'}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="balance">
                        <span>{{row.balance}} {{row.currency}}</span>
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button type="error" size="small" @click="delAccount('smsto', row.uname)">删除</Button>
                    </template>
                </Table>
                <!-- 分页 -->
                <Page
                    :total="TPPlatformApiAccounts.smsto.length"
                    :current="smsto.page"
                    :page-size="smsto.size"
                    show-total
                    show-elevator
                    @on-change="page => smsto.page = page"
                    transfer
                    style="margin:24px 0;text-align:center;"/>
            </TabPane>
        </Tabs>
    </Card>
    <Modal
        v-model="addAccountVisible"
        :title="'增加账号' + tabName"
        @on-ok="addAccount">
        <Form v-model="addAccountFormObj" label-position="left" :label-width="100" label-colon>
            <FormItem label="用户名">
                <Input v-model="addAccountFormObj.uname" clearable style="width:350px;"/>
            </FormItem>
            <FormItem label="apiKey">
                <Input v-model="addAccountFormObj.apiKey" clearable style="width:350px;"/>
            </FormItem>
            <FormItem label="apiSecret">
                <Input :disabled="tabName === 'smsto'" v-model="addAccountFormObj.apiSecret" clearable style="width:350px;"/>
            </FormItem>
        </Form>
    </Modal>
</div>
</template>
<script>
export default {
    name: 'ChannelApikey',
    data () {
        return {
            columns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '用户名',
                    key: 'uname',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '状态',
                    slot: 'error',
                    align: 'center'
                },
                {
                    title: '余额',
                    slot: 'balance',
                    align: 'center'
                },
                {
                    title: 'apiKey',
                    key: 'apiKey',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: 'apiSecret',
                    key: 'apiSecret',
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 100,
                    align: 'center'
                }
            ],
            TPPlatformApiAccounts: {
                abosend: [],
                vonage: [],
                smsto: []
            },
            abosend: {
                page: 1,
                size: 10
            },
            vonage: {
                page: 1,
                size: 10
            },
            smsto: {
                page: 1,
                size: 10
            },
            tabName: 'abosend',
            addAccountVisible: false,
            addAccountFormObj: {
                uname: '',
                apiKey: '',
                apiSecret: ''
            },
            tableLoading: false
        }
    },
    methods: {
        onClickTab(name) {
            this.tabName = name;
        },
        _fetchTPPlatformApiAccounts() {
            this.tableLoading = true;
            this.apiV1({
                method: 'get',
                url: '/setting/TPPlatformApiAccounts',
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.TPPlatformApiAccounts = data;
                }
            }).finally(() => this.tableLoading = false);
        },
        delAccount(platform, uname) {
            this.$Modal.confirm({
                title: '操作确认',
                content: `<p>删除 <b>${platform}</b> 账号 <b>${uname}</b>，是否继续？</p>`,
                loading: true,
                onOk: () => {
                    this.apiV1({
                        method: 'delete',
                        url: '/setting/TPPlatformApiAccounts',
                        params: {
                            platform,
                            uname
                        },
                        headers: {'Authorization': this.token}
                    }).then(response => {
                        const { success } = response.data;
                        if (success) {
                            this.$Message.success('删除成功。');
                            this._fetchTPPlatformApiAccounts();
                        }
                    }).finally(() => this.$Modal.remove());
                }
            });
        },
        openAddAccount() {
            this.addAccountFormObj = {
                uname: '',
                apiKey: '',
                apiSecret: ''
            };
            this.addAccountVisible = true;
        },
        addAccount() {
            if (!this.addAccountFormObj.uname || !this.addAccountFormObj.apiKey || (this.tabName !== 'smsto' && !this.addAccountFormObj.apiSecret)) {
                this.$Message.error('信息填写不完整。');
                return;
            }
            this.apiV1({
                method: 'put',
                url: '/setting/TPPlatformApiAccounts',
                data: {
                    platform: this.tabName,
                    ...this.addAccountFormObj
                },
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Message.success('增加成功。');
                    this._fetchTPPlatformApiAccounts();
                }
            });
        },
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetchTPPlatformApiAccounts();
    }
}
</script>
<style scoped lang="scss">
</style>
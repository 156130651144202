<template>
  <div class="container">
      <div class="search-bar">
        <span>选择通道：</span>
        <Select v-model="searchObj.channel" style="width:200px;" @on-change="_fetch">
            <Option v-for="item of channels" :value="item" :key="item">{{item}}</Option>
        </Select>
        <div class="sum">
            <span style="color:#2d8cf0;">累计扣量:{{sumdata.deduction}}</span>
            <span style="color:#ff9900;">累计支出:N/A</span>
            <span style="color:#19be6b;">累计收入:{{sumdata.consume}}</span>
        </div>
      </div>
      <Divider />
      <Table :columns="columns" :data="data.rows" :loading="tableLoading">
          <template slot="sumpay">
            <span>N/A</span>
          </template>
          <template slot="pay">
            <span>N/A</span>
          </template>
      </Table>
      <!-- 分页 -->
      <Page
        :total="data.count"
        :current="searchObj.page"
        :page-size="searchObj.size"
        show-total
        show-elevator
        @on-change="onPageChange"
        transfer
        style="margin:24px 0;text-align:center;"/>
  </div>
</template>

<script>
export default {
    name: 'ChannelStatistics',
    data () {
        return {
            tableLoading: false,
            searchObj: {
                channel: 'vonage',
                page: 1,
                size: 10
            },
            data: {
                count: 0,
                rows: []
            },
            columns: [
                {
                    title: '日期',
                    key: 'date',
                    align: 'center'
                },
                {
                    title: '累计扣量',
                    key: 'sumdeduction',
                    align: 'center'
                },
                {
                    title: '累计支出',
                    slot: 'sumpay',
                    align: 'center'
                },
                {
                    title: '累计收入',
                    key: 'sumconsume',
                    align: 'center'
                },
                {
                    title: '今日扣量',
                    key: 'deduction',
                    align: 'center'
                },
                {
                    title: '今日支出',
                    slot: 'pay',
                    align: 'center'
                },
                {
                    title: '今日收入',
                    key: 'consume',
                    align: 'center'
                },
            ],
            sumdata: {
                pay: '0.00',
                consume: '0.00',
                deduction: '0'
            },
            channels: ['vonage', 'smsto']
        }
    },
    methods: {
        _fetch() {
            this.tableLoading = true;
            this.apiV1({
                method: 'get',
                url: '/channel/statistics',
                params: this.searchObj,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.data = data.data;
                    this.sumdata = data.sumdata;
                }
            }).finally(() => this.tableLoading = false);
        },
        onPageChange(page) {
            this.searchObj.page = page;
            this._fetch();
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    }
}
</script>

<style scoped lang="scss">
.sum {
    display: inline-block;
    margin-left: 24px;
    span {
        margin-right: 24px;
    }
}
</style>
<template>
<div class="container">
    <div class="search-bar">
        <span>订单状态：</span>
        <Select v-model="searchObj.status" style="width:200px;" @on-change="_fetch">
            <Option v-for="item of ordstatusarr" :value="item.value" :key="item.label">{{item.label}}</Option>
        </Select>
        <Input v-model="searchObj.kw" search enter-button @on-search="_fetch" placeholder="搜索用户ID" style="width:200px;margin-left:24px;"/>
        <Icon type="md-refresh" size="28" class="clickable" @click="_fetch" title="刷新" style="margin: 0 24px;"/>
        <Button type="primary" @click="createOrderVisible = true">创建任务</Button>
    </div>
    <Divider />
    <Table :columns="columns" :data="data.rows" :loading="tableLoading">
        <template slot-scope="{ row }" slot="fullPhone">
            {{row.user.fullPhone}}
        </template>
        <template slot-scope="{ row }" slot="sendTime">
            {{moment(row.sendTime).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template slot-scope="{ row }" slot="status">
            <Badge :status="orderStatusMap[row.status].color" />
            <span>{{orderStatusMap[row.status].text}}</span>
        </template>
        <template slot-scope="{ row }" slot="createdAt">
            {{moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" @click="openexamine(row)" :disabled="row.status !== 0">审核</Button>
            <Button type="info" size="small" @click="openexamine(row)" style="margin:0 10px;">查看</Button>
            <Button type="error" size="small" @click="delorder(row)">删除</Button>
        </template>
    </Table>
    <!-- 分页 -->
    <Page
        :total="data.count"
        :current="searchObj.page"
        :page-size="searchObj.size"
        show-total
        show-elevator
        @on-change="onPageChange"
        transfer
        style="margin:24px 0;text-align:center;"/>
    <!-- 订单审核 -->
    <Modal
        v-model="examineVisible"
        title="订单审核/查看"
        @on-ok="examine">
        <Form v-model="examineFormObj" label-position="left" :label-width="100" label-colon>
            <FormItem label="订单ID">
                {{examineFormObj.id}}
            </FormItem>
            <FormItem label="文档条数">
                {{examineFormObj.fileName ? examineFormObj.batchImport.length : examineFormObj.countTotal}}
                <a @click="downloadNumbers(examineFormObj.id)">下载原文档(TXT)</a>
                <a @click="batchImport1('file-input-1')" style="margin-left:10px;">重新上传(TXT)</a>
            </FormItem>
            <input id="file-input-1" type="file" name="name" style="display: none;" @change="batchImport2('examineFormObj', 'file-input-1')"/>
            <FormItem label="发送内容">
                <Input type="textarea" v-model="examineFormObj.content" :rows="5" placeholder="请输入发送内容"></Input>
            </FormItem>
            <FormItem label="审核结果">
                <Select v-model="examineFormObj.status" style="width:200px;">
                    <Option :value="2">审核通过</Option>
                    <Option :value="1">审核拒绝</Option>
                </Select>
            </FormItem>
            <FormItem v-if="examineFormObj.status === 1" label="审核意见">
                <Input v-model="examineFormObj.rejectReason" type="textarea" :rows="5" placeholder="输入拒绝原因..." style="width:300px;"/>
            </FormItem>
            <template v-else>
                <FormItem label="扣量">
                    <InputNumber :active-change="false" :min="0" :max="examineFormObj.countTotal" v-model="examineFormObj.deduction" style="width: 115px;"></InputNumber>
                </FormItem>
                <FormItem label="短信单价(元)">
                    <InputNumber :active-change="false" :min="0.01" :max="10" :step="0.1" v-model="examineFormObj.price" style="width: 115px;"></InputNumber>
                    <span style="margin-left:10px;">总价：{{((examineFormObj.fileName ? examineFormObj.batchImport.length : examineFormObj.countTotal) * examineFormObj.price).toFixed(2)}}</span>
                </FormItem>
                <FormItem label="选择通道">
                    <Select v-model="examineFormObj.platform" style="width:200px;">
                        <Option value="abosend">abosend</Option>
                        <Option value="vonage">vonage</Option>
                        <Option value="smsto">smsto</Option>
                    </Select>
                    <Table ref="TTPAccountTable" :columns="columns_ttpa" :data="ttpAccounts">
                        <template slot-scope="{ row }" slot="balance">
                            <span>{{row.balance}} {{row.currency}}</span>
                        </template>
                    </Table>
                </FormItem>
                <FormItem label="发送时间">
                    <RadioGroup v-model="examineFormObj.sendTimeMode">
                        <Radio label="immediate">立即发送</Radio>
                        <Radio label="timing">定时发送</Radio>
                    </RadioGroup>
                    <template v-if="examineFormObj.sendTimeMode === 'timing'">
                        <br>
                        <DatePicker type="datetime" placeholder="自定义发送时间" style="width: 200px;" v-model="examineFormObj.timing"></DatePicker>
                    </template>
                </FormItem>
            </template>
        </Form>
    </Modal>
    <!-- 创建订单 -->
    <Modal
        v-model="createOrderVisible"
        title="创建任务"
        @on-ok="createOrder">
        <Form v-model="createOrderFormObj" label-position="left" :label-width="100" label-colon>
            <FormItem label="用户ID">
                <Input type="text" v-model="createOrderFormObj.uid" placeholder="请输入用户ID（必填）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="发送号码">
                <Input type="textarea" v-model="createOrderFormObj.manualInput" :rows="5" placeholder="请输入手机号码，一行一个号码"></Input>
                <p style="color: #062ff7;font-size:12px;">*格式必须为:国际区号+号码。如：8613900000000,区号与号码之间不加空格</p>
                <p style="color: #062ff7;font-size:12px;">*大批量发送请使用文件导入，文件仅支持TxT文本，格式必须区号+号码，区号与号码之间不加空格”。</p>
                <p style="color: #062ff7;font-size:12px;">*手动输入最大支持500个手机号码，超过500请使用TxT文本批量导入。</p>
                <p style="color: #062ff7;font-size:12px;">*提交时会自动过滤无效号码。</p>
            </FormItem>
            <Button type="primary" size="small" @click="batchImport1('file-input')">TXT文本批量导入</Button>
            <span v-if="createOrderFormObj.fileName" style="margin-left:10px;color:#999;">{{createOrderFormObj.fileName}}（号码数量：{{createOrderFormObj.batchImport.length}}）</span>
            <input id="file-input" type="file" name="name" style="display: none;" @change="batchImport2('createOrderFormObj', 'file-input')"/>
            <br><br>
            <Tooltip placement="top-start" theme="light" max-width="400">
                <div slot="content">
                    <h1>txt示例</h1>
                    <p> 8617305921234</p>
                    <p> 8617305921235</p>
                    <p> 8617305921236</p>
                    <p style="color:#999;">*超过10万条号码以上的文件，建议分批次上传发送</p>
                </div>
                <a>TXT导入示例</a>
            </Tooltip>
            <br><br>
            <FormItem label="发送时间">
                <RadioGroup v-model="createOrderFormObj.sendTimeMode">
                    <Radio label="immediate">立即发送</Radio>
                    <Radio label="timing">定时发送</Radio>
                </RadioGroup>
                <template v-if="createOrderFormObj.sendTimeMode === 'timing'">
                    <br>
                    <DatePicker type="datetime" placeholder="自定义发送时间" style="width: 200px;" v-model="createOrderFormObj.timing"></DatePicker>
                </template>
            </FormItem>
            <FormItem label="发送内容">
                <Input type="textarea" v-model="createOrderFormObj.content" :rows="5" placeholder="请输入发送内容"></Input>
            </FormItem>
        </Form>
    </Modal>
</div>
</template>
<script>
import {saveAs} from '../fileSaver.js';
export default {
    name: 'OrderManage',
    data () {
        return {
            columns: [
                {
                    title: '订单ID',
                    key: 'id',
                    width: 100,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '用户ID',
                    key: 'uid',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '客户手机号',
                    slot: 'fullPhone',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '订单状态',
                    slot: 'status',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '计划发送时间',
                    slot: 'sendTime',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '发送内容',
                    key: 'content',
                    width: 150,
                    tooltip: true
                },
                {
                    title: '本次文档条数',
                    key: 'countTotal',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '实际发送条数',
                    key: 'countFact',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '发送成功条数',
                    key: 'countOk',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '发送失败条数',
                    key: 'countNo',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '本次消费金额',
                    key: 'money',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '审核意见',
                    key: 'rejectReason',
                    width: 200,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '创建时间',
                    slot: 'createdAt',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                    width: 200,
                    fixed: 'right'
                }
            ],
            data: {
                count: 0,
                rows: []
            },
            tableLoading: false,
            searchObj: {
                status: -1,
                kw: '',
                page: 1,
                size: 10
            },
            ordstatusarr: [
                { label: '全部', value: -1 },
                { label: '审核中', value: 0 },
                { label: '审核拒绝', value: 1 },
                { label: '等待发送', value: 2 },
                { label: '发送中', value: 3 },
                { label: '完成', value: 4 }
            ],
            orderStatusMap: {
                0: { color: 'warning', text: '待审核' },
                1: { color: 'error', text: '审核拒绝' },
                2: { color: 'default', text: '等待发送' },
                3: { color: 'processing', text: '发送中' },
                4: { color: 'success', text: '完成' }
            },
            examineVisible: false,
            examineFormObj: {
                id: null,
                content: '',
                countTotal: 0,
                fileName: '',
                batchImport: [],
                sendTimeMode: 'immediate',
                timing: '',
                status: 2,
                rejectReason: '',
                deduction: 0,
                price: 0.1,
                platform: 'abosend',
                uname: ''
            },
            TPPlatformApiAccounts: {
                abosend: [],
                vonage: [],
                smsto: []
            },
            columns_ttpa: [
                {
                    type: 'selection',
                    width: 55,
                    align: 'center'
                },
                {
                    title: '用户名',
                    key: 'uname',
                    width: 200,
                    tooltip: true
                },
                {
                    title: '余额',
                    slot: 'balance',
                    width: 150
                }
            ],
            createOrderVisible: false,
            createOrderFormObj: {
                uid: '',
                manualInput: '',
                fileName: '',
                batchImport: [],
                sendTimeMode: 'immediate',
                timing: '',
                content: ''
            }
        }
    },
    methods: {
        _fetch() {
            this.tableLoading = true;
            this.apiV1({
                method: 'get',
                url: '/smsOrder/list',
                params: this.searchObj,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.data = {
                        count: data.count,
                        rows: data.rows.map(elem => {
                            elem.rejectReason = elem.rejectReason || 'N/A';
                            return elem;
                        })
                    };
                }
            }).finally(() => this.tableLoading = false);
        },
        _fetchTPPlatformApiAccounts() {
            this.apiV1({
                method: 'get',
                url: '/setting/TPPlatformApiAccounts',
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.TPPlatformApiAccounts = data;
                }
            });
        },
        onPageChange(page) {
            this.searchObj.page = page;
            this._fetch();
        },
        openexamine(row) {
            this.examineFormObj = {
                id: row.id,
                content: row.content,
                countTotal: row.countTotal,
                fileName: '',
                batchImport: [],
                sendTimeMode: 'immediate',
                timing: '',
                status: 2,
                rejectReason: '',
                deduction: row.deduction,
                price: row.price,
                platform: 'abosend',
                uname: ''
            };
            this.examineVisible = true;
            this._fetchTPPlatformApiAccounts();
        },
        examine() {
            const selected = this.$refs.TTPAccountTable.getSelection();
            if (selected.length === 0) {
                this.$Message.warning('未指定发送账号');
                return;
            }
            if (!this.examineFormObj.content) {
                this.$Message.warning('未指定发送内容');
                return;
            }
            let sendTime;
            if (this.examineFormObj.sendTimeMode === 'immediate') {
                sendTime = new Date();
            } else {
                sendTime = this.examineFormObj.timing;
            }
            sendTime = this.moment(sendTime).format('YYYY-MM-DD HH:mm:ss');
            this.examineFormObj.uname = selected[0].uname;
            this.apiV1({
                method: 'post',
                url: '/smsOrder/examine',
                data: {
                    ...this.examineFormObj,
                    sendTime
                },
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '操作成功'
                    });
                    this._fetch();
                }
            })
        },
        createOrder() {
            if (!this.createOrderFormObj.uid) {
                this.$Message.error('请输入用户ID');
                return;
            }
            const phoneNumberArr = [...this.createOrderFormObj.batchImport];
            if (this.createOrderFormObj.manualInput) {
                const arr = this.createOrderFormObj.manualInput.split('\n').map(elem => elem.trim());
                const numbers = arr.filter(elem => elem.match(/^\d+$/)).slice(0, 500);
                phoneNumberArr.push(...numbers);
            }
            if (!phoneNumberArr.length) {
                this.$Message.error('请输入手机号');
                return;
            }
            if (!this.createOrderFormObj.content) {
                this.$Message.error('请输入发送内容');
                return;
            }
            let sendTime;
            if (this.createOrderFormObj.sendTimeMode === 'immediate') {
                sendTime = new Date();
            } else {
                sendTime = this.createOrderFormObj.timing;
            }
            sendTime = this.moment(sendTime).format('YYYY-MM-DD HH:mm:ss');
            this.apiV1({
                method: 'post',
                url: '/smsOrder/create',
                data: {
                    uid: this.createOrderFormObj.uid,
                    phoneNumberArr,
                    sendTime,
                    content: this.createOrderFormObj.content
                },
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '任务创建成功'
                    });
                    this.createOrderFormObj = {
                        uid: '',
                        manualInput: '',
                        fileName: '',
                        batchImport: [],
                        sendTimeMode: 'immediate',
                        timing: '',
                        content: ''
                    };
                    this._fetch();
                }
            });
        },
        batchImport1(id) {
            document.getElementById(id).click();
        },
        batchImport2(attr, id) {
            const file = document.getElementById(id).files[0];
            // const task_name = file.name.split('.')[0];
            if (file) {
                this[attr].fileName = file.name;
                const reader = new FileReader();
                reader.readAsText(file, "utf8");
                reader.onload = () => {
                    const data = reader.result.split('\n').map(elem => elem.trim()).filter(elem => elem.match(/^\d+$/));
                    this[attr].batchImport = data;
                    if (attr === 'examineFormObj') {
                        this.$Notice.success({
                            title: '上传成功，号码数量：' + data.length
                        });
                    }
                }
            }
        },
        downloadNumbers(ordId) {
            this.apiV1({
                method: 'get',
                url: '/smsOrder/downloadNumbers',
                params: {
                    ordId
                },
                headers: {'Authorization': this.token}
            }).then(response => {
                const { success, data } = response.data;
                if (success) {
                    const file = new File([Buffer.from(data)], `E快讯_手机号码${Date.now()}.txt`, { type: "charset=utf-8" });
                    saveAs(file);
                    this.$Modal.success({
                        title: 'E快讯',
                        content: '已为您自动导出下载到本地TXT文本文档'
                    });
                }
            });
        },
        delorder(row) {
            this.$Modal.confirm({
                title: '操作确认',
                content: `<p>删除订单任务 <b>（ID:${row.id}</b>），是否继续？</p>`,
                loading: true,
                onOk: () => {
                    this.apiV1({
                        method: 'delete',
                        url: '/smsOrder/destroy/' + row.id,
                        headers: {'Authorization': this.token}
                    }).then(response => {
                        const { success } = response.data;
                        if (success) {
                            this.$Message.success('删除成功。');
                            this._fetch();
                        }
                    }).finally(() => this.$Modal.remove());
                }
            });
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    },
    computed: {
        // 计算属性的 getter
        ttpAccounts: function () {
            return this.TPPlatformApiAccounts[this.examineFormObj.platform];
        },
    }
}
</script>
<style scoped lang="scss">
.container {
    .search-bar {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .actbtn {
        margin: 0 2px;
    }
}
</style>
<template>
<div class="container">
    <div class="search-bar">
        <Button type="primary" @click="createUserVisible = true">创建账号</Button>
        <Input v-model="searchObj.kw" search enter-button @on-search="_fetch" placeholder="搜索用户ID/手机号/邮箱/备注" style="width:350px;margin:0 24px;"/>
        <Icon type="md-refresh" size="28" class="clickable" @click="_fetch" title="刷新"/>
    </div>
    <Divider />
    <Table :columns="columns" :data="data.rows" :loading="tableLoading">
        <template slot-scope="{ row }" slot="createdAt">
            {{moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template slot-scope="{ row }" slot="action">
            <Button type="info" size="small" @click="openUserBill(row)">余额</Button>
            <Button type="primary" size="small" @click="openEditor(row)" style="margin-left:10px;">编辑</Button>
            <Button type="error" size="small" @click="delUser(row)" style="margin-left:10px;">删除</Button>
        </template>
    </Table>
    <!-- 分页 -->
    <Page
        :total="data.count"
        :current="searchObj.page"
        :page-size="searchObj.size"
        show-total
        show-elevator
        @on-change="onPageChange"
        transfer
        style="margin:24px 0;text-align:center;"/>
    <!-- 人工充值 -->
    <Modal
        v-model="rechargeVisible"
        title="人工充值"
        @on-ok="recharge">
        <Form v-model="rechargeFormObj" label-position="left" :label-width="100" label-colon>
            <FormItem label="充值金额">
                <Input type="number" v-model="rechargeFormObj.amount" placeholder="请输入充值金额" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="手机号">
                {{rechargeFormObj.fullPhone}}
            </FormItem>
            <FormItem label="账户余额￥">
                {{rechargeFormObj.balance}}
            </FormItem>
        </Form>
    </Modal>
    <!-- 创建账号 -->
    <Modal
        v-model="createUserVisible"
        title="创建账号"
        @on-ok="createUser">
        <Form v-model="createUserFormObj" label-position="left" :label-width="100" label-colon>
            <FormItem label="手机号">
                <Input type="text" v-model="createUserFormObj.phoneNumber" placeholder="请输入手机号（必填）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="邮箱">
                <Input type="email" v-model="createUserFormObj.email" placeholder="请输入邮箱号（选填）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="密码">
                <Input type="text" v-model="createUserFormObj.loginPw" placeholder="请输入登录密码（必填）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="备注">
                <Input type="textarea" v-model="createUserFormObj.remarks" :rows="5" placeholder="请输入备注（选填）" style="width:300px;">
                </Input>
            </FormItem>
        </Form>
    </Modal>
    <!-- 编辑账号 -->
    <Modal
        v-model="updateUserVisible"
        title="编辑账号"
        @on-ok="updateUser">
        <Form v-model="updateUserFormObj" label-position="left" :label-width="100" label-colon>
            <FormItem label="手机号">
                <Input type="text" v-model="updateUserFormObj.phoneNumber" placeholder="请输入手机号（必填）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="邮箱">
                <Input type="email" v-model="updateUserFormObj.email" placeholder="请输入邮箱号（选填）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="新密码">
                <Input type="text" v-model="updateUserFormObj.loginPw" placeholder="请输入新密码（如无需修改，请不要输入）" style="width:300px;">
                </Input>
            </FormItem>
            <FormItem label="备注">
                <Input type="textarea" v-model="updateUserFormObj.remarks" :rows="5" placeholder="请输入备注（选填）" style="width:300px;">
                </Input>
            </FormItem>
        </Form>
    </Modal>
    <!-- 余额明细 -->
    <Modal
        v-model="billVisible"
        :title="'用户 ' + searchObj_bill.id + ' 余额明细'"
        width="840"
        footer-hide>
        <Button type="primary" icon="ios-card" @click="openRecharge" size="small">充值</Button>
        <Table :columns="columns_bill" :data="userBill.rows" :loading="ubTableLoading">
            <template slot-scope="{ row }" slot="type">
                <span>{{billTypes.find(elem => elem.value === row.type).label}}</span>
            </template>
            <template slot-scope="{ row }" slot="status">
                <Badge :status="billStatusMap[row.status].color" />
                <span>{{billStatusMap[row.status].text}}</span>
            </template>
            <template slot-scope="{ row }" slot="amount">
                <span v-if="row.amount < 0" style="color:#ed4014;">{{row.amount}}</span>
                <span v-else style="color:#19be6b;">{{row.amount}}</span>
            </template>
        </Table>
        <!-- 分页 -->
        <Page
            :total="userBill.count"
            :current="searchObj_bill.page"
            :page-size="searchObj_bill.size"
            show-total
            show-elevator
            @on-change="onPageChangeBill"
            transfer
            size="small"
            style="margin:24px 0;text-align:center;"/>
    </Modal>
</div>
</template>
<script>
export default {
    name: 'CustomerManage',
    data () {
        return {
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '手机号',
                    key: 'fullPhone',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '邮箱',
                    key: 'email',
                    width: 200,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '备注',
                    key: 'remarks',
                    width: 200,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '账户余额',
                    key: 'balance',
                    width: 100,
                    align: 'center'
                },
                {
                    title: '注册时间',
                    slot: 'createdAt',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '备用联系人',
                    key: 'spareContactName',
                    width: 200,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '联系人手机',
                    key: 'spareContactPhone',
                    width: 200,
                    align: 'center',
                    tooltip: true
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                    width: 200,
                    fixed: 'right'
                }
            ],
            data: {
                count: 0,
                rows: []
            },
            tableLoading: false,
            searchObj: {
                kw: '',
                page: 1,
                size: 10
            },
            rechargeFormObj: {
                uid: null,
                amount: 0.00,
                balance: 0.00,
                fullPhone: ''
            },
            rechargeVisible: false,
            createUserVisible: false,
            createUserFormObj: {
                phoneNumber: '',
                email: '',
                loginPw: '',
                remarks: ''
            },
            updateUserVisible: false,
            updateUserFormObj: {
                id: '',
                phoneNumber: '',
                email: '',
                loginPw: '',
                remarks: ''
            },
            billVisible: false,
            userBill: {
                count: 0,
                rows: []
            },
            columns_bill: [
                {
                    title: '时间',
                    key: 'createdAt',
                    width: 200,
                    align: 'center'
                },
                {
                    title: '类型',
                    slot: 'type',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '状态',
                    slot: 'status',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '金额',
                    slot: 'amount',
                    width: 150,
                    align: 'center'
                },
                {
                    title: '余额',
                    key: 'balance',
                    width: 150,
                    align: 'center'
                }
            ],
            searchObj_bill: {
                id: '',
                balance: 0.00,
                fullPhone: '',
                page: 1,
                size: 10
            },
            ubTableLoading: false,
            billTypes: [
                { value: 0, label: '在线充值' },
                { value: 1, label: '人工充值' },
                { value: 2, label: '消费' }
            ],
            billStatusMap: {
                0: { color: 'processing', text: '待支付' },
                1: { color: 'success', text: '成功' }
            }
        }
    },
    methods: {
        _fetch() {
            this.tableLoading = true;
            this.apiV1({
                method: 'get',
                url: '/customer/list',
                params: this.searchObj,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.data = {
                        count: data.count,
                        rows: data.rows.map(elem => {
                            elem.remarks = elem.remarks || 'N/A';
                            elem.email = elem.email || 'N/A';
                            elem.spareContactName = elem.spareContactName || 'N/A';
                            elem.spareContactPhone = elem.spareContactPhone || 'N/A';
                            return elem;
                        })
                    };
                }
            }).finally(() => this.tableLoading = false);
        },
        onPageChange(page) {
            this.searchObj.page = page;
            this._fetch();
        },
        openUserBill(row) {
            this.searchObj_bill = {
                id: row.id,
                balance: row.balance,
                fullPhone: row.fullPhone,
                page: 1,
                size: 10
            };
            this.billVisible = true;
            this._fetchUserBill();
        },
        _fetchUserBill() {
            this.ubTableLoading = true;
            this.apiV1({
                method: 'get',
                url: '/customer/bill/' + this.searchObj_bill.id,
                params: this.searchObj_bill,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    this.userBill = data;
                }
            }).finally(() => this.ubTableLoading = false);
        },
        onPageChangeBill(page) {
            this.searchObj_bill.page = page;
            this._fetchUserBill();
        },
        openEditor(row) {
            this.updateUserFormObj = {
                id: row.id,
                phoneNumber: row.phoneNumber,
                email: row.email === 'N/A' ? '':row.email,
                loginPw: '',
                remarks: row.remarks === 'N/A' ? '':row.remarks
            };
            this.updateUserVisible = true;
        },
        delUser(row) {
            this.$Modal.confirm({
                title: '操作确认',
                content: `<p>删除账号 <b>（ID:${row.id}</b>），是否继续？</p>`,
                loading: true,
                onOk: () => {
                    this.apiV1({
                        method: 'delete',
                        url: '/customer/destroy/' + row.id,
                        headers: {'Authorization': this.token}
                    }).then(response => {
                        const { success } = response.data;
                        if (success) {
                            this.$Message.success('删除成功。');
                            this._fetch();
                        }
                    }).finally(() => this.$Modal.remove());
                }
            });
        },
        createUser() {
            if (!this.createUserFormObj.phoneNumber || !this.createUserFormObj.loginPw) {
                this.$Message.error('必填项不能为空');
                return;
            }
            this.apiV1({
                method: 'post',
                url: '/customer/create',
                data: this.createUserFormObj,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '创建账号成功'
                    });
                    this.createUserFormObj = {
                        phoneNumber: '',
                        email: '',
                        loginPw: '',
                        remarks: ''
                    };
                    this._fetch();
                }
            })
        },
        updateUser() {
            if (!this.updateUserFormObj.phoneNumber) {
                this.$Message.error('必填项不能为空');
                return;
            }
            this.apiV1({
                method: 'put',
                url: '/customer/edit/' + this.updateUserFormObj.id,
                data: this.updateUserFormObj,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '编辑账号成功'
                    });
                    this._fetch();
                }
            })
        },
        openRecharge() {
            this.rechargeFormObj = {
                uid: this.searchObj_bill.id,
                amount: 0.00,
                balance: this.searchObj_bill.balance,
                fullPhone: this.searchObj_bill.fullPhone
            };
            this.rechargeVisible = true;
        },
        recharge() {
            this.apiV1({
                method: 'post',
                url: '/customer/recharge',
                data: this.rechargeFormObj,
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Notice.success({
                        title: '充值成功'
                    });
                    this._fetchUserBill();
                    this._fetch();
                }
            })
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    }
}
</script>
<style scoped lang="scss">
.container {
    .search-bar {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .actbtn {
        margin: 0 2px;
    }
}
</style>
<template>
<div class="container">
     <Card>
         <p slot="title">
            <Icon type="md-happy" />
            基础配置
        </p>
        <Form v-model="basicConf" label-position="left" :label-width="100" label-colon>
            <FormItem label="短信单价">
                <InputNumber :active-change="false" :step="0.01" :min="0.01" :max="2" v-model="basicConf.unitPrice" style="width: 100px;"></InputNumber>
            </FormItem>
            <FormItem label="在线充值提示">
                <Input v-model="basicConf.rechargeTips" clearable style="width:350px;"/>
            </FormItem>
            <FormItem label="用户注册提示">
                <Input v-model="basicConf.sendSmsCodeTips" clearable style="width:350px;"/>
            </FormItem>
            <FormItem label="首页公告">
                <Input type="textarea" v-model="basicConf.indexNotice" :rows="5"/>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="saveBasicConf">保存</Button>
            </FormItem>
        </Form>
     </Card>
</div>
</template>
<script>
export default {
    name: 'SysSetting',
    data () {
        return {
            basicConf: {
                unitPrice: '0.1',
                rechargeTips: '',
                indexNotice: '',
                sendSmsCodeTips: ''
            }
        }
    },
    methods: {
        _fetch() {
            this.apiV1({
                method: 'get',
                url: '/setting/basicConf',
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success, data } = response.data;
                if (success) {
                    for (const { key, value } of data) {
                        this.basicConf[key] = value;
                    }
                }
            });
        },
        saveBasicConf() {
            const items = Object.entries(this.basicConf).map(elem => {
                return {
                    key: elem[0],
                    value: elem[1]
                };
            });
            this.apiV1({
                method: 'put',
                url: '/setting/basicConf',
                data: {
                    items
                },
                headers: {'Authorization': this.token}
            })
            .then(response => {
                const { success } = response.data;
                if (success) {
                    this.$Message.success('保存成功');
                    this._fetch();
                }
            });
        }
    },
    mounted() {
        this.token = this.$store.state.token;
        this._fetch();
    }
}
</script>
<style scoped lang="scss">
</style>